<template>
  <div>
    <div v-show="totalInvalidCustomers" class="modal fade text-center" id="confirmModal" tabindex="-1"
        aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div class="modal-dialog modalImage">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="normalizationsHistoryLabel"> <i class="fas fa-users-cog"></i> Validação de contatos</h5>
                </div>
                <div class="modal-body d-flex justify-content-center">
                  <p v-if="totalInvalidCustomers">
                    Tem certeza que deseja normalizar {{ totalInvalidCustomers }} contatos ?
                  </p> 
                  <p v-else>Não há contatos para normalizar.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="closeModal('#confirmModal')"><b>Cancelar</b></button>
                    <button v-if="totalInvalidCustomers" id="normalize" class="btn btn-success h-25 mt-auto" @click="normalizeCustomers(company)">Normalizar</button>
                </div>
            </div>
        </div>
      </div>
      
      <div v-show="normalizations" class="modal fade text-center" id="normalizationsHistory" tabindex="-1"
        aria-labelledby="normalizationsHistoryLabel" aria-hidden="true">
        <div class="modal-dialog modalImage modal-lg">
            <div class="modal-content history">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="normalizationsHistoryLabel"> <i class="fas fa-users-cog"></i> Validação de contatos</h5>
                </div>
                <div class="modal-body d-flex justify-content-center" id="historycontent">
                  <table class="col-lg-10 table-modal">
                    <thead>
                      <td class="text-left font-weight-bold col-md-2">Empresa</td>
                      <td class="text-center font-weight-bold col-md-3">Realização</td>
                      <td class="text-center font-weight-bold col-md-2">Total</td>
                      <td class="text-center font-weight-bold col-md-2">Sucesso</td>
                      <td class="text-center font-weight-bold col-md-2">Erro</td>
                      <td class="text-center font-weight-bold col-md-2">Status</td>
                    </thead>

                    <tr v-for="(normalization, index) in normalizations" :key="index">
                      <td class="text-left">{{ normalization.company ? normalization.company.name : 'Todas' }}</td>
                      <td class="text-center ">{{ new Date(normalization.created_at).toLocaleDateString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                        }) }}</td>
                      <td class="text-center">{{ normalization.total_to_validate || 0 }}</td>
                      <td class="text-center">{{ normalization.total_customers }}</td>
                      <td class="text-center">
                          {{ normalization.total_customers_error || 0 }}
                          <button @click="generateReport('validation', normalization._id)" title="Relatório do detalhamento de erro" v-if="normalization.total_customers_error" class="ml-2 info text-secondary btn">
                            <font-awesome-icon icon="fa-solid fa-download" />
                          </button>
                      </td>
                      <td class="text-center">
                          <i v-if="normalization.status == 'done'" class="fas fa-check text-success" title="Concluída"></i>
                          <i v-else-if="normalization.status == 'error'" class="fas fa-exclamation-circle text-danger" title="Falha"></i>
                          <i v-else class="fas fa-spinner text-warning" title="Executando"></i>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal('#normalizationsHistory')"><b>Fechar</b></button>
                </div>
            </div>
        </div>
      </div>

      <div v-show="totalOptCustomers" class="modal fade text-center" id="confirmOptModal" tabindex="-1"
        aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div class="modal-dialog modalImage">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="confirmModalLabel"><i class="fas fa-user-check"></i> Opt-in de contatos</h5>
                </div>
                <div class="modal-body d-flex justify-content-center">
                  <p v-if="totalOptCustomers">
                    Tem certeza que deseja realizar opt-in de <span class="font-weight-bold text-danger">{{ totalOptCustomers }}</span> contatos ?
                  </p> 
                  <p v-else>Não há contatos para normalizar.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="closeModal('#confirmOptModal')"><b>Cancelar</b></button>
                    <button v-if="totalOptCustomers" id="optin" class="btn btn-success h-25 mt-auto" @click="optinCustomers(optcompany)">Confirmar</button>
                </div>
            </div>
        </div>
      </div>

       <div v-show="optins" class="modal fade text-center" id="optinsHistory" tabindex="-1"
        aria-labelledby="optinsHistoryLabel" aria-hidden="true">
        <div class="modal-dialog modalImage modal-lg">
            <div class="modal-content history">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="optinsHistoryLabel"> <i class="fas fa-users-cog"></i> Opt-in de contatos</h5>
                </div>
                <div class="modal-body d-flex justify-content-center" id="historycontent">
                  <table class="col-lg-10 table-modal">
                    <tr>
                      <td class="text-left font-weight-bold col-md-2">Empresa</td>
                      <td class="text-center font-weight-bold col-md-3">Realização</td>
                      <td class="text-center font-weight-bold col-md-2">Total</td>
                      <td class="text-center font-weight-bold col-md-2">Sucesso</td>
                      <td class="text-center font-weight-bold col-md-2">Error</td>
                      <td class="text-center font-weight-bold col-md-2">Status</td>
                    </tr>

                    <tr v-for="(optin, index) in optins" :key="index">
                      <td class="text-left">{{ optin.company ? optin.company.name : 'Todas' }}</td>
                      <td class="text-center ">{{ new Date(optin.created_at).toLocaleDateString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                        }) }}</td>
                      <td class="text-center">{{ optin.total_opted_in || 0 }}</td>
                      <td class="text-center">{{ optin.total_customers || 0 }}</td>
                      <td class="text-center">
                        {{ optin.total_customers_error || 0 }}
                        <button @click="generateReport('optin', optin._id)" title="Relatório do detalhamento de erro" v-if="optin.total_customers_error" class="ml-2 info text-secondary btn">
                          <font-awesome-icon icon="fa-solid fa-download" />
                        </button>
                      </td>
                      <td class="text-center">
                          <i v-if="optin.status == 'done'" class="fas fa-check text-success" title="Concluída"></i>
                          <i v-else-if="optin.status == 'error'" class="fas fa-exclamation-circle text-danger" title="Falha"></i>
                          <i v-else class="fas fa-spinner text-warning" title="Executando"></i>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal('#optinsHistory')"><b>Fechar</b></button>
                </div>
            </div>
        </div>
      </div>

      <div v-show="totalMigrateCustomers" class="modal fade text-center" id="confirmMigrateModal" tabindex="-1"
        aria-labelledby="confirmModalLabel" aria-hidden="true">
        <div class="modal-dialog modalImage">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="normalizationsHistoryLabel"> <i class="fas fa-users-cog"></i> Migração de contatos</h5>
                </div>
                <div class="modal-body d-flex justify-content-center">
                  <p v-if="totalMigrateCustomers">
                    Tem certeza que deseja normalizar {{ totalMigrateCustomers }} contatos ?
                  </p> 
                  <p v-else>Não há contatos para normalizar.</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" @click="closeModal('#confirmMigrateModal')"><b>Cancelar</b></button>
                    <button v-if="totalMigrateCustomers" id="migrate" class="btn btn-success h-25 mt-auto" @click="migrateCustomers(migrationCompany, broker)">Migrar</button>
                </div>
            </div>
        </div>
      </div>

      <div v-show="migrations" class="modal fade text-center" id="migrationsHistory" tabindex="-1"
        aria-labelledby="optinsHistoryLabel" aria-hidden="true">
        <div class="modal-dialog modalImage modal-lg">
            <div class="modal-content history">
                <div class="modal-header">
                    <h5 class="modal-title font-weight-bold" id="optinsHistoryLabel"> <i class="fas fa-users-cog"></i> Migração de contatos</h5>
                </div>
                <div class="modal-body d-flex justify-content-center" id="historycontent">
                  <table class="col-lg-10 table-modal">
                    <tr>
                      <td class="text-left font-weight-bold col-md-2">Empresa</td>
                      <td class="text-center font-weight-bold col-md-2">Realização</td>
                      <td class="text-center font-weight-bold col-md-2">Contatos</td>
                      <td class="text-center font-weight-bold col-md-2">Status</td>
                    </tr>

                    <tr v-for="(migration, index) in migrations" :key="index">
                      <td class="text-left">{{ migration.company ? migration.company.name : 'Todas' }}</td>
                      <td class="text-center ">{{ new Date(migration.created_at).toLocaleDateString('pt-BR', {
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                        second: 'numeric'
                        }) }}</td>
                      <td class="text-center">{{ migration.total_customers }}</td>
                      <td class="text-center">
                          <i v-if="migration.status == 'done'" class="fas fa-check text-success" title="Concluída"></i>
                          <i v-else-if="migration.status == 'error'" class="fas fa-exclamation-circle text-danger" title="Falha"></i>
                          <i v-else class="fas fa-spinner text-warning" title="Executando"></i>
                      </td>
                    </tr>
                  </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="closeModal('#migrationsHistory')"><b>Fechar</b></button>
                </div>
            </div>
        </div>
      </div>

      <div class="content-wrapper">
        <section class="content-header p-1 px-2">
          <div class="div-header container-fluid">
            <div style="border-bottom: 1px solid #0001" class="row mb-2">
              <div class="col-sm-12 d-flex justify-content-between align-items-center">

                <h4 style="color: #0007" class="mt-3">
                  <i class="fas fa-tools"></i>
                  Normalizações
                </h4>
              </div>
            </div>
          </div>
        </section>

        <div class="container-fluid">
          <div class="row align-items-end">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h3 class="card-title font-weight-bold mr-2"><i class="fas fa-users"></i> Migração de contatos</h3>
                  <button class="info" data-toggle="tooltip" data-placement="right" title="Funcionalidade para realizar as migrações de todos os contatos da empresa  para o broker selecionado."><font-awesome-icon icon="fa-solid fa-circle-info" /></button>
                </div>
                <div class="card-body p-0">
                  <div class="content">
                    <form>
                      <div class="label col-lg-12">
                        <div class="row form-group">
                          <div class="col-lg-5">
                            <label class="word-custom">Empresa</label>
                            <select
                                type="text"
                                id="optcompany"
                                name="company"
                                class="form-control" 
                                v-model="migrationCompany"
                                required
                            >
                              <option value="" disabled selected>Selecione...</option>
                              <option v-for="(item, index) in companies" :value="item._id" :key="index">{{ item.name }}</option>
                            </select>
                          </div>
                          <div class="col-lg-3">
                          <label class="word-custom">Broker</label>
                            <select
                                type="text"
                                id="migrateBroker"
                                name="broker"
                                class="form-control" 
                                v-model="broker"
                                required
                            >
                              <option value="" disabled selected>Selecione...</option>
                              <option value="gupshup">Gupshup</option>
                              <option value="dialog">Dialog</option>
                              <option value="zapi">Z-api</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  <button class="btn btn-success ml-2 h-25 mt-auto" @click="totalToMigrateCustomers(migrationCompany, broker)" data-bs-target="#confirmOptModal">Confirmar</button>
                  <button class="btn btn-primary ml-2 h-25 mt-auto" @click="getHistoryMigrations()" title="Histórico"><i class="fas fa-history"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row align-items-end">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h3 class="card-title font-weight-bold mr-2">  <i class="fas fa-users-cog"></i> Validação de contatos</h3>
                  <button class="info" data-toggle="tooltip" data-placement="right" title="Funcionalidade para realizar a validação do id do whatsapp de todos os contatos da empresa selecionada."><font-awesome-icon icon="fa-solid fa-circle-info" /></button>
                </div>
                <div class="card-body p-0">
                  <div class="content">
                    <form>
                      <div class="label col-lg-10">
                        <div class="row form-group">
                          <div class="col-lg-6">
                            <label class="word-custom">Empresa</label>
                            <select
                                type="text"
                                id="perfil"
                                name="perfil"
                                class="form-control" 
                                v-model="company"
                            >
                              <option value="" disabled selected>Selecione...</option>
                              <option v-for="(item, index) in companies" :value="item._id" :key="index">{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  <button class="btn btn-success ml-2 h-25 mt-auto" @click="totalOfInvalidCustomers(company)" data-bs-target="#confirmModal">Normalizar</button>
                  <button class="btn btn-primary ml-2 h-25 mt-auto" @click="getHistoryNormalizations()" title="Histórico"><i class="fas fa-history"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="container-fluid">
          <div class="row align-items-end">
            <div class="col-12">
              <div class="card">
                <div class="card-header d-flex align-items-center">
                  <h3 class="card-title font-weight-bold mr-2"> <i class="fas fa-user-check"></i> Opt-in de contatos</h3>
                  <button class="info" data-toggle="tooltip" data-placement="right" title="Funcionalidade para realizar o opted-in de todos os contatos da empresa selecionada."><font-awesome-icon icon="fa-solid fa-circle-info" /></button>
                </div>
                <div class="card-body p-0">
                  <div class="content">
                    <form>
                      <div class="label col-lg-10">
                        <div class="row form-group">
                          <div class="col-lg-6">
                            <label class="word-custom">Empresa</label>
                            <select
                                type="text"
                                id="optcompany"
                                name="company"
                                class="form-control" 
                                v-model="optcompany"
                                required
                            >
                              <option value="" disabled selected>Selecione...</option>
                              <option v-for="(item, index) in companiesGupshup" :value="item._id" :key="index">{{ item.name }}</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </form>
                  <button class="btn btn-success ml-2 h-25 mt-auto" @click="totalOptinCustomers(optcompany)" data-bs-target="#confirmOptModal">Confirmar</button>
                  <button class="btn btn-primary ml-2 h-25 mt-auto" @click="getHistoryOptins()" title="Histórico"><i class="fas fa-history"></i></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import helpers from '../../../assets/helpers/index'
import { CompanyService } from "../../../services/company.service";
import { IntegrationTypeEnum } from "../../../shared/domain/enum/integration-type.enum";
import xlsx from 'json-as-xlsx'

export default {
  name: "index-normalizations",
  data() {
    return {
      helpers,
      companies: [],
      normalizations: [],
      pt: { 
        language: 'Brazilian', 
        months: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'], 
        monthsAbbr: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'], 
        days: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
        yearSuffix: '' },
      totalInvalidCustomers: 0,
      totalOptCustomers: 0,
      totalMigrateCustomers: 0,
      company: '',
      optcompany: '',
      optins: [],
      migrationCompany: '',
      migrationBroker: '',
      broker:'',
      migrations: [],
      companiesGupshup: []
    }
  },

  watch: {
  },

  computed: {
    generateCols() {
      return [
        {
          label: 'Nome',
          key: 'name_contact' 
        },
        {
          label: 'Telefone',
          key: 'cel_phone_contact' 
        },
        {
          label: 'Erro',
          key: 'error' 
        }
      ]
    }
  },

  methods: {
    async generateReport(type, id) {
      const strategies = {
        validation: async (id) => await this.getDataValidation(id),
        optin: async (id) => await this.getDataOptin(id)
      }
      const cols = this.generateCols
      const report = await strategies[type](id)
      report.map(rep => {
        Reflect.set(rep, 'error', type === 'validation' ? 'inválido' : 'Sem opt-in')
      })
      const data = [
        {
          sheet: "Contatos com erro",
          columns: cols.map(col => ({label: col.label, value: col.key})),
          content: report
        }
      ]
      const settings = {
        fileName: "Planilha Contatos com erro",
        extraLength: 3,
        writeOptions: {}
      }

      let callback = () => this.$toasted.global.defaultSuccess({msg: 'Arquivo gerado com sucesso'})

      xlsx(data, settings, callback)
    },

    async getDataValidation(validationId) {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      const { data } = await this.$tallos_admin_api
        .get(`normalizations/report/normalization-contact/${validationId}`)
      return data
    },

    async getDataOptin(optedId) {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      const { data } = await this.$tallos_admin_api
        .get(`normalizations/report/company-optedIn/${optedId}`)
      return data
    },

    getCompanies() {
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .get("companies/all-official")
        .then(res => {
          this.companies = res.data.all;
          this.companiesGroup = res.data.all;
          this.companiesGupshup = res.data.gupshup;
        })
        .catch(e => {
          this.$toasted.global.defaultError({
            msg: "Erro ao buscar empresas."
          });
        });
    },

    totalOfInvalidCustomers(company){
      let body = {  }
      if(company){
        body['company'] = company
      } else {
        this.$toasted.global.defaultError({
              msg: "Selecione uma empresa"
        }); 
        return;
      }

      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .post("companies/normalizations/customers", body)
        .then(res => {
          this.totalInvalidCustomers = res.data.totalInvalidCustomers;
          $('#confirmModal').modal('toggle')
        })
        .catch(e => {
          this.$toasted.global.defaultError({
            msg: "Erro ao buscar total de contatos."
          });
        });
    },

    totalOptinCustomers(company){
      let body = {  }
      if(company){
        body['company'] = company
      } else {
          this.$toasted.global.defaultError({
              msg: "Selecione uma empresa"
          }); 
          return;
      }
      const foundCompany = CompanyService.findOneFromArray(this.companies, company);
      if (foundCompany && foundCompany.whatsapps_integrations_type === IntegrationTypeEnum.DIALOG) {
        this.$toasted.global.defaultError({
          msg: "Não é possível realizar esta operação para empresas com integração no Broker Dialog360"
        }); 
        return;
      }

      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .post("companies/optin/customers", body)
        .then(res => {
          this.totalOptCustomers = res.data.totalOptinCustomers;
          $('#confirmOptModal').modal('toggle')
        })
        .catch(e => {
          this.$toasted.global.defaultError({
            msg: "Erro ao buscar total de contatos."
          });
        });
    },

    totalToMigrateCustomers(company, broker){
      if(!company){
        this.$toasted.global.defaultError({
          msg: "Selecione uma empresa"
        }); 
        return;
      }

      if(!broker){
        this.$toasted.global.defaultError({
          msg: "Selecione um broker"
        }); 
        return;
      }

      let body = {
        company,
        broker
      }

      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.$tallos_admin_api
        .post("companies/migration/customers", body)
        .then(res => {
          this.totalMigrateCustomers = res.data.totalMigrateCustomers;
          $('#confirmMigrateModal').modal('toggle')
        })
        .catch(e => {
          this.$toasted.global.defaultError({
            msg: "Erro ao buscar total de contatos."
          });
        });
    },

    closeModal(modal){
        $(modal).modal('toggle')
    },

    normalizeCustomers: async function (company) {

      $('#normalize').prop("disabled", true)

      let body = {}

      if(company){
        body['company'] = company
      }

      $('#confirmModal').modal('toggle')  
      this.$toasted.global.defaultSuccess({
        msg: "A normalização foi iniciada."
      }); 

      $('#normalize').prop("disabled", false)


      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      this.helpers.requestToAPI(this, 'whatsapp-official/normalize-whatsapp-id', body, 'POST')
				.then(response => { 
				})
				.catch(function (error) {
          this.$toasted.global.defaultError({
            msg: "Erro ao normalizar contatos."
          });
				})

    },

    optinCustomers: async function (company) {

      $('#optin').prop("disabled", true)

      let body = {}

      if(company){
        body['company'] = company
      } else {
          this.$toasted.global.defaultError({
              msg: "Selecione uma empresa"
          }); 
          return;
      }

      $('#confirmOptModal').modal('toggle')  
        this.$toasted.global.defaultSuccess({
          msg: "A normalização foi iniciada."
      }); 

      $('#optin').prop("disabled", false)

      this.helpers.requestToAPI(this, 'whatsapp-official/company-opt-in', body, 'POST')
				.then(response => { 
				})
				.catch(function (error) {
          this.$toasted.global.defaultError({
            msg: "Erro ao realizar optin de contatos."
          });
				})

    },

    migrateCustomers: async function (company, broker) {

      $('#migrate').prop("disabled", true)

      let body = {
        company,
        broker
      }

      $('#confirmMigrateModal').modal('toggle')  
      this.$toasted.global.defaultSuccess({
        msg: "A normalização foi iniciada."
      }); 

      $('#migrate').prop("disabled", false)

      this.helpers.requestToAPI(this, 'whatsapp-official/migrate-whatsapp-company', body, 'POST')
				.then(response => { 
				})
				.catch(function (error) {
          this.$toasted.global.defaultError({
            msg: "Erro ao normalizar contatos."
          });
				})

    },

    getHistoryNormalizations: async function(){

      await this.$tallos_admin_api
        .get("normalizations/normalization-contact")
				.then(response => {
          this.normalizations = response.data
          $('#normalizationsHistory').modal('toggle')
				})
				.catch(function (error) {
          this.$toasted.global.defaultError({
            msg: "Erro ao carregar histórico."
          });
				})

    },

    getHistoryOptins: async function(){

      await this.$tallos_admin_api
        .get("normalizations/company-optedIn")
				.then(response => {
          this.optins = response.data
          $('#optinsHistory').modal('toggle')
				})
				.catch(function (error) {
          this.$toasted.global.defaultError({
            msg: "Erro ao carregar histórico."
          });
				})

    },

    getHistoryMigrations: async function(){
      this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
      await this.$tallos_admin_api
        .get("normalizations/company-migrations")
				.then(response => {
          this.migrations = response.data
          $('#migrationsHistory').modal('toggle')
				})
				.catch(function (error) {
          this.$toasted.global.defaultError({
            msg: "Erro ao carregar histórico."
          });
				})

    },
    
    formatDate(date, type) {
      let data = ''
      if(type == 'start_date'){
        data = moment(date).add(3, 'hours').format('DD/MM/YYYY');
      } else {
        data = moment(date).format('DD/MM/YYYY')
      }
      return data
    }
  },

  created() {
    this.getCompanies();
    document.title = "Tallos Admin - Normalizações";
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  },

  mounted() {
    this.$tallos_admin_api.defaults.headers.common["Authorization"] = `Bearer ${this.$store.getters.tokenJWT}`;
  },
}
</script>

<style scoped>
.content-wrapper {
  background-color: #fff;
}

button {
  padding: 1vh;
  font-weight: bold;
}

.table-modal {
  display: block; 
  max-height: 40vh; 
  overflow-y: scroll;
}

.content {
  padding: 20px;
}

#historycontent{
  max-height: 50%;
}

/* width */
::-webkit-scrollbar {
  height: 5px !important;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #dbdbdb; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b5b5b5; 
}

.info {
  all: unset;
}

</style>
