export class CompanyService {
  /**
   * @param { Array } companies
   * @param { string } companyId
   */
   static findOneFromArray(companies, companyId) {
    if (!companies.length || !companyId) {
      return null;
    }
    return companies.find((company) => company._id === companyId);
  }
}
